(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/grassroots-toaster/assets/javascripts/grassroots-toaster.js') >= 0) return;  svs.modules.push('/components/sport/grassroots-toaster/assets/javascripts/grassroots-toaster.js');

'use strict';

svs.sport = svs.sport || {};
svs.sport.GrassrootsToaster = function () {
  const log = new svs.core.log.Logger('svs.sport.GrassrootsToaster');
  const INFO_TYPE = 'grassroots';
  const show = args => {
    const expires = svs.components.Storage.constants.EXPIRES_1MONTH * 60000;
    const dismissed = svs.components.Storage.browser.get('toasterdismissed', 'grassroots');
    const dismissedCount = typeof dismissed === 'object' ? dismissed.count : dismissed + 0 || 0;
    const dismissedTime = typeof dismissed === 'object' ? dismissed.time : 0;
    const askForToaster = dismissedCount === 0 || dismissedCount < 3 && dismissedTime + expires < new Date().getTime();
    if (askForToaster) {
      const dialog = new svs.components.dialog.Confirm({
        branding: svs.components.dialog.branding.SPORT,
        icon: 'favorites',
        autoClose: false,
        area: svs.components.dialog.area.TOASTER,
        title: 'Stötta dina favoritföreningar med Gräsroten',
        infoType: INFO_TYPE,
        message: [{
          type: svs.components.dialog.message.TEXT,
          text: 'Välj upp till tre favoritföreningar som får ditt stöd varje gång du spelar.'
        }],
        actions: [{
          title: dismissedCount >= 2 ? 'Visa inte igen' : 'Påminn mig senare',
          callback() {
            svs.components.Storage.browser.set('toasterdismissed', 'grassroots', {
              count: dismissedCount + 1,
              time: new Date().getTime()
            }, svs.components.Storage.constants.EXPIRES_1YEAR, err => {
              err && log.error('failed to persist receiver toaster state', err);
            });
            dialog.close();
            args && args.onDismiss && args.onDismiss();
          }
        }, {
          title: 'Välj favoriter',
          callback() {
            location.href = svs.core.urlMapping.get('grassrootsHome');
            args && args.onSuccess && args.onSuccess();
          }
        }]
      });
      svs.components.dialog.api.add(dialog);
    } else {
      args && args.onDismiss && args.onDismiss();
    }
  };
  return {
    INFO_TYPE,
    show
  };
};

 })(window);